<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="7"
          class="d-none d-lg-block position-relative overflow-hidden pa-0 bg-white"
        >
          <div class="d-flex flex-column align-center justify-center pa-16 pe-0 auth-illustrator-wrapper">
            <div>
              <h2
                class="text-color pb-2"
              >
                GESTIÓN DE PROYECTOS Y PROGRAMAS
              </h2>
              <v-img
                contain
                max-width="80%"
                :src="require(`@/assets/images/my-images/name-app.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="5"
          class="d-flex align-center auth-bg pa-0 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card
                flat
                color="#333130"
              >
                <v-card-title class="pt-2 pb-1 ma-0 d-flex align-center justify-center">
                  <v-img
                    contain
                    max-width="30%"
                    :src="require(`@/assets/images/my-images/login-logo.png`)"
                  ></v-img>
                </v-card-title>
                <v-card-text>
                  <p
                    class="text-2xl font-weight-semibold mb-1 color-inputs text-center"
                  >
                    Bienvenido a GESPROY! 👋🏻
                  </p>
                  <p class="mb-1 color-inputs text-center">
                    Por favor inicie sesión en su cuenta para empezar!
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    @submit.prevent="handleFormSubmit"
                    ref="loginForm"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Usuario"
                      placeholder="Usuario"
                      hide-details="auto"
                      dark
                      class="mb-6"
                      :rules="[validators.required, validators.emailValidator]"
                      :error-messages="errorMessages.email"
                    >
                      <template v-slot:prepend>
                        <v-icon color="primary">
                          {{ icons.mdiAccount }}
                        </v-icon>
                      </template>
                    </v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      dark
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                      :rules="[validators.required]"
                      :error-messages="errorMessages.password"
                    >
                      <template v-slot:prepend>
                        <v-icon color="primary">
                          {{ icons.mdiLock }}
                        </v-icon>
                      </template>
                    </v-text-field>

                    <div
                      class="d-flex align-center justify-space-between flex-wrap"
                    >
                      <v-checkbox
                        hide-details
                        dark
                        label="Recordar datos de ingreso"
                        class="mb-1 mt-0"
                        v-model="remember"
                      >
                      </v-checkbox>

                      <!-- forget link -->
                      <!-- <a
                        class="ms-3 "
                        href="#"
                      > ¿Olvidó la Contraseña? </a> -->
                    </div>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                      :loading="loading"
                    >
                      Iniciar Sesión
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-1"
                >
                  <p class="mb-0 me-1 color-inputs">
                    ¿Nuevo en la plataforma?
                  </p>
                  <a href="#"> Solicitar Registro </a>
                </v-card-text>

                <!-- divider -->
                <!--  <v-card-text class="d-flex align-center mt-1">
                  <v-divider color="#e7e3fc"></v-divider>
                  <span class="mx-5 color-inputs">o</span>
                  <v-divider color="#e7e3fc"></v-divider>
                </v-card-text>
  -->
                <!-- social links -->
                <!-- <v-card-actions class="d-flex justify-center">
                  <v-btn
                    icon
                    class="ms-1"
                  >
                    <v-icon
                      :color="$vuetify.theme.dark ? socialLink.colorInDark:socialLink.color"
                    >
                      {{ socialLink.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiGoogle, mdiEyeOutline, mdiEyeOffOutline, mdiAccount, mdiLock } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { Base64 } from 'js-base64'
import { required, emailValidator } from '@core/utils/validation'
import { validateForm } from '@/@core/utils'
import { useRouter } from '@core/utils'
import apiAxios from '../plugins/axios'
import themeConfig from '@themeConfig'
import { defineAbilitiesFor } from '@/plugins/acl/config'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const email = ref('')
    const errorMessages = ref([])
    const isPasswordVisible = ref(false)
    const loading = ref(false)
    const loginForm = ref(null)
    const password = ref('')
    const remember = ref(false)
    const { router } = useRouter()

    const socialLink = {
      icon: mdiGoogle,
      color: '#db4437',
      colorInDark: '#db4437',
    }

    const setCookie = (name, value, expireDays) => {
      let exDate = new Date()
      exDate = exDate.setDate(exDate.getDate() + expireDays)
      document.cookie =
        name + '=' + value + (expireDays === undefined ? '' : ';expires=' + new Date(exDate).toUTCString())
    }

    const getCookie = key => {
      if (document.cookie.length > 0) {
        let start = document.cookie.indexOf(key + '=')
        if (start !== -1) {
          start = start + key.length + 1
          let end = document.cookie.indexOf(';', start)

          if (end === -1) {
            end = document.cookie.length
            return Base64.decode(document.cookie.substring(start, end))
          } else {
            return document.cookie.substring(start, end)
          }
        }
      }
      return ''
    }

    const init = () => {
      const mail = getCookie('email')
      const psd = getCookie('psd')
      if (mail && psd) {
        email.value = mail
        password.value = psd
      }
    }

    const handleFormSubmit = async () => {
      try {
        const isFormValid = validateForm(loginForm)
        if (!isFormValid) return
        const user = {
          email: email.value,
          password: password.value,
        }
        loading.value = true

        const response = await apiAxios.post('login', { user })

        // access token
        const { token } = response.data
        loading.value = false
        localStorage.setItem('accessToken', token)

        const userData = response.data.user
        const { fullname } = response.data.profile
        userData.fullName = fullname
        const role = response.data.selected_role
        let abilities = defineAbilitiesFor(role)

        localStorage.setItem('userAbility', JSON.stringify(abilities))
        vm.$ability.update(abilities)
        localStorage.setItem('role', JSON.stringify(role))
        localStorage.setItem('userData', JSON.stringify(userData))

        if (token) router.push('/proyectos/lista')
        // remember me
        if (remember.value) {
          setCookie('email', email.value)
          const psd = Base64.encode(password.value)
          setCookie('psd', psd, 30)
        } else {
          setCookie('email', '', 0)
          setCookie('psd', '', 0)
        }
      } catch (error) {
        console.log(error)
        loading.value = false
      }
    }

    init()

    return {
      email,
      errorMessages,
      handleFormSubmit,
      isPasswordVisible,
      loading,
      loginForm,
      password,
      remember,
      socialLink,

      // Icons
      icons: {
        mdiAccount,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiLock,
      },

      // validators
      validators: {
        emailValidator,
        required,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
.bg-white {
  background-color: white;
}
.color-inputs {
  color: #e7e3fc;
}
.text-color {
  color: #333130;
}
</style>
